<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <v-row>
      <v-col align="center" class="mt-15">
        <v-col cols="12" xl="8" lg="12" md="12" sm="12" xs="12">
          <v-row>
            <v-col align="left" cols="12" class="mt-15">
              <p class="caHeadline">Results</p>
            </v-col>
            <v-row>
              <v-col
                v-for="(item, index) in resourceFolders"
                :Key="index"
                cols="12"
                xl="4"
                lg="4"
                md="6"
                sm="12"
                xs="12"
              >
                <v-card
                  @click="displayResource(item.template, item.description)"
                  class="resourceCard"
                  style="height: 150px"
                >
                  <p
                    v-if="item.title == 'IO4'"
                    class="text-center pt-12 pb-8"
                    style="font-weight: bold"
                  >
                    {{ $t("resources.IO4Description") }}
                  </p>
                  <p
                    v-else-if="item.title == 'IO5'"
                    class="text-center pt-12 pb-8"
                    style="font-weight: bold"
                  >
                    {{ $t("resources.IO5Description") }}
                  </p>
                  <p
                    v-else-if="item.title == 'IO6'"
                    class="text-center pt-12 pb-8"
                    style="font-weight: bold"
                  >
                    {{ $t("resources.IO6Description") }}
                  </p>
                  <p
                    v-else-if="
                      item.title == 'Podcast' || item.title == 'Podcast'
                    "
                    class="text-center caSubtitle pt-12 pb-8"
                  >
                    {{ $t("resources.podcast") }}
                  </p>
                  <p
                    v-else-if="item.title == 'Sound' || item.title == 'Lyd'"
                    class="text-center caSubtitle pt-12 pb-8"
                  >
                    {{ $t("resources.sound") }}
                  </p>
                  <p
                    v-else-if="item.title == 'Other' || item.title == 'Annet'"
                    class="text-center caSubtitle pt-12 pb-8"
                  >
                    {{ $t("resources.other") }}
                  </p>
                  <p v-else>...</p>
                  <!-- <v-divider class="newsCardDividerPositioning" style="border: 0" :style="`background-color:${colorArr[index]}`"></v-divider>
                  <v-col cols="12" class="ma-0 pa-0" style="padding-top: 6px !important"></v-col> -->
                </v-card>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center" class="mb-15">
        <v-col cols="12" xl="8" lg="12" md="12" sm="12" xs="12">
          <v-row>
            <v-row>
              <v-col cols="12" class="pt-5 mb-15">
                <!-- <v-card flat> -->
                <v-row>
                  <!-- Default -->
                  <template v-if="resourceTemplate == 0">
                    <!-- <v-col cols="12">
                      <p class="text-center title">HOME</p>
                    </v-col> -->
                  </template>

                  <!-- No content added yet -->
                  <template v-else-if="resourceContent == ''">
                    <v-col
                      cols="12"
                      align="left"
                      xl="9"
                      lg="9"
                      md="9"
                      sm="12"
                      xs="12"
                      class="pb-0"
                    >
                      <p class="caTitle mt-4">No content added</p>
                    </v-col>
                    <v-col
                      cols="12"
                      align="right"
                      align-self="center"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      xs="12"
                      class="d-flex align-end flex-column mt-3"
                    >
                      <!-- <v-btn @click="closeTheResourceBox()">X</v-btn> -->
                      <!-- <span @click="closeTheResourceBox()" class="material-icons-outlined closeIcon">close</span> -->
                      <span
                        @click="closeTheResourceBox()"
                        class="material-icons-outlined iconbutton closeIcon text-center mr-2"
                        style="
                          background-image: url('/img/icon button bg green.svg');
                          margin-bottom: 2px;
                        "
                      >
                        <div style="transform: translateY(12px)">close</div>
                      </span>
                    </v-col>
                  </template>

                  <!-- Display Content -->
                  <template v-else-if="resourceContent">
                    <v-col cols="12">
                      <h3 style="font-weight: bold; margin-bottom: 20px">
                        {{ clickedCard }}
                      </h3>
                    </v-col>
                    <v-col
                      class="pa-3"
                      v-for="(resources, resourceIndex) in resourceContent"
                      :key="resourceIndex"
                      cols="12"
                      xl="6"
                      lg="6"
                      md="12"
                      sm="12"
                      xs="12"
                    >
                      <v-col
                        style="
                          box-shadow: 0px 2px 16px #00000033;
                          border-radius: 7px;
                        "
                        class="pa-3"
                        cols="12"
                      >
                        <!-- <pre>{{resource}}</pre> -->
                        <v-card height="100%" flat>
                          <!-- Media above title, sort by type -->
                          <p
                            class="mb-3 ml-4 mt-1 text-left"
                            style="font-weight: bold"
                          >
                            {{ resources.title }}
                          </p>
                          <!-- {{ $t('resource.other') }} -->
                          <!-- Languages -->

                          <v-col
                            cols="12"
                            v-for="(
                              language, languageIndex
                            ) in resources.languages"
                            :Key="languageIndex"
                            class="pb-0 pt-0 mb-4"
                          >
                            <v-card
                              v-if="language.link"
                              height="100%"
                              @click="test(language.link)"
                              class="resourceCard"
                            >
                              <!-- <pre>{{language}}</pre> -->
                              <v-row>
                                <v-col
                                  cols="1"
                                  align-self="center"
                                  class="ml-4"
                                >
                                  <v-img
                                    v-if="language.language == 'English'"
                                    src="@/assets/flags/england.png"
                                    alt="English Flag"
                                    min-height="30"
                                    min-width="30"
                                    max-height="30"
                                    max-width="30"
                                    contain
                                  ></v-img>
                                  <v-img
                                    v-if="language.language == 'Italian'"
                                    src="@/assets/flags/italy.png"
                                    alt="Italian Flag"
                                    min-height="25"
                                    min-width="25"
                                    max-height="25"
                                    max-width="25"
                                    contain
                                    class="ml-1"
                                  ></v-img>
                                  <v-img
                                    v-if="language.language == 'Norwegian'"
                                    src="@/assets/flags/norway.png"
                                    alt="Norwegian Flag"
                                    min-height="25"
                                    min-width="25"
                                    max-height="25"
                                    max-width="25"
                                    contain
                                    class="ml-1"
                                  ></v-img>
                                  <v-img
                                    v-if="language.language == 'Polish'"
                                    src="@/assets/flags/polishFlag.png"
                                    alt="Danish Flag"
                                    min-height="25"
                                    min-width="25"
                                    max-height="25"
                                    max-width="25"
                                    contain
                                    class="ml-1"
                                  ></v-img>
                                  <v-img
                                    v-if="language.language == 'Greek'"
                                    src="@/assets/flags/GreeceFlagg.png"
                                    alt="Danish Flag"
                                    min-height="25"
                                    min-width="25"
                                    max-height="25"
                                    max-width="25"
                                    contain
                                    class="ml-1"
                                  ></v-img>
                                  <v-img
                                    v-if="language.language == 'Danish'"
                                    src="@/assets/flags/denmark.png"
                                    alt="Danish Flag"
                                    min-height="25"
                                    min-width="25"
                                    max-height="25"
                                    max-width="25"
                                    contain
                                    class="ml-1"
                                  ></v-img>
                                </v-col>
                                <v-col
                                  align="left"
                                  class="title pt-5"
                                  style="transform: translateY(3px)"
                                >
                                  <p>{{ language.language }}</p>
                                </v-col>
                                <v-col align="right" align-self="center">
                                  <!-- <span
                                    class="material-icons-outlined iconbutton text-center mr-2"
                                    style="background-image: url('/img/icon button bg green.svg'); margin-bottom: 2px"
                                  >
                                    <div style="transform: translateY(12px)" class="">open_in_new</div>
                                  </span> -->
                                  <span
                                    class="material-icons-outlined"
                                    style="
                                      transform: translateY(3px)
                                        translateX(-19px);
                                      padding: 5px;
                                    "
                                    >open_in_new</span
                                  >
                                </v-col>
                              </v-row>
                              <!-- <v-divider></v-divider> -->
                            </v-card>
                          </v-col>
                        </v-card>
                      </v-col>
                    </v-col>
                  </template>
                </v-row>
                <!-- </v-card> -->
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <!-- Resource Content -->
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clickedCard: "",
      colorArr: [
        "#205072",
        "#329D9C",
        "#D83636",
        "#DD9A30",
        "#205072",
        "#329D9C",
      ],
      resourceFolders: [
        {
          title: this.$t("resources.IO4Title"),
          colour: "",
          image: "",
          description: this.$t("resources.IO4Description"),
          template: 1,
        },
        {
          title: this.$t("resources.IO5Title"),
          colour: "",
          image: "",
          description: this.$t("resources.IO5Description"),
          template: 2,
        },
        {
          title: this.$t("resources.IO6Title"),
          colour: "",
          image: "",
          description: this.$t("resources.IO6Description"),
          template: 3,
        },
        // { title: this.$i18n.t("resources.podcast"), colour: "", image: "", description: "", template: 4 },
        // { title: this.$i18n.t("resources.sound"), colour: "", image: "", description: "", template: 5 },
        // { title: this.$i18n.t("resources.other"), colour: "", image: "", description: "", template: 6 },
      ],
      /* Easy way to display the content
      
      */
      resourceTemplate: 0,
      resourceContent: [],
      i04Resources: [
        {
          title: this.$t("resources.i40ResourceSectionTitle"),
          content: "",
          type: "",
          languages: [
            {
              language: "English",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/1c2b751f-6f1a-4454-8ae8-d12a949121c1.pdf",
            },
            {
              language: "Italian",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/f31a61a3-64ee-42d6-bbb0-b22b3c103cf3.pdf",
            },
            {
              language: "Norwegian",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/3e81ad6a-6f42-46a3-96d8-0210a0c4132c.pdf",
            },
            // { language: "Danish", link: "https://www.google.com/" },
            {
              language: "Polish",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/981184e4-3c61-41be-9ddd-b1ae30ba305a.pdf",
            },
            {
              language: "Greek",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/e36f1e2c-dfb1-48e7-86b8-86e37661530a.pdf",
            },
          ],
        },
      ],

      i05Resources: [
        {
          title: this.$t("resources.i50ResourceSectionTitle"),
          content: "",
          type: "",
          languages: [
            {
              language: "English",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/88a63cf0-94bf-4a74-b407-1fb122c1f538.pdf",
            },
            {
              language: "Italian",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/bcc1047e-cead-4064-ab5f-c7abeb1bfdb6.pdf",
            },
            {
              language: "Norwegian",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/2edaa1b3-086e-4b23-b3a5-7528bc217037.pdf",
            },
            // { language: "Danish", link: "https://www.google.com/" },
            {
              language: "Polish",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/36b90feb-f051-49fb-86d7-e4d71d37d071.pdf",
            },
            {
              language: "Greek",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/88f737fb-35d3-441e-b504-2cd087828125.pdf",
            },
          ],
        },
      ],
      i06Resources: [
        {
          title: this.$t("resources.i60ResourceSectionTitle"),
          content: "",
          type: "",
          languages: [
            {
              language: "English",
              link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2F4f05ca3d-3e2e-44ab-bd3e-a00d0a72a5c7.pptx&wdOrigin=BROWSELINK",
            },
            {
              language: "Italian",
              link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2F4dde8d74-04c2-46fd-b168-5a309a944119.pptx&wdOrigin=BROWSELINK",
            },
            {
              language: "Norwegian",
              link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2Fee7acda2-19fd-4e0a-b4f3-e712c5d73a44.pptx&wdOrigin=BROWSELINK",
            },
            // { language: "Danish", link: "https://www.google.com/" },
            {
              language: "Polish",
              link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2F6c0b282f-e3f3-4850-b2f6-bc73814244bc.pptx&wdOrigin=BROWSELINK",
            },
            {
              language: "Greek",
              link: "https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fprioskompetanse.blob.core.windows.net%2Ffollowupmedia%2Fuploads%2Feca4817b-891f-4de5-8fae-e618472c95c8.pptx&wdOrigin=BROWSELINK",
            },
          ],
        },
        {
          title: this.$t("resources.i60ResourceSectionTitleTwo"),
          content: "",
          type: "",
          languages: [
            {
              language: "English",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/32d6ba85-a0bf-4fea-8f3b-478de407bb3f.pdf",
            },
            {
              language: "Italian",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/0c759711-bdb6-47fa-ac26-9c50a3cdab1b.pdf",
            },
            {
              language: "Norwegian",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/de996ef2-a536-4d00-a310-cc94b92fec5b.pdf",
            },
            // { language: "Danish", link: "https://www.google.com/" },
            {
              language: "Polish",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/d1f74cfd-df39-4d58-ac4c-5aa96899f2ed.pdf",
            },
            {
              language: "Greek",
              link: "https://prioskompetanse.blob.core.windows.net/followupmedia/uploads/ed8aeedf-dbf3-4a81-bb3f-14770417a229.pdf",
            },
          ],
        },
      ],
    };
  },
  methods: {
    // Close the Bottom Box
    closeTheResourceBox() {
      this.resourceTemplate = 0;
      this.resourceContent = [];
    },

    // Display the specific Resource Content
    displayResource(templateID, templateDescription) {
      if (this.resourceTemplate == templateID) {
        this.closeTheResourceBox();
      } else {
        this.clickedCard = templateDescription;
        this.resourceTemplate = templateID;
      }
      if (this.resourceTemplate == 1) {
        this.resourceContent = this.i04Resources;
      } else if (this.resourceTemplate == 2) {
        this.resourceContent = this.i05Resources;
      } else if (this.resourceTemplate == 3) {
        this.resourceContent = this.i06Resources;
      } else {
        this.resourceContent = [];
      }
    },
    test(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped>
.closeIcon {
  cursor: pointer;
  transition: ease 0.2s;
  /* color: var(--brand-color-main); */
}
.closeIcon:hover {
  color: white;
}
.resourceCard {
  border-radius: 7px !important;
  border: 2px solid var(--brand-color-main) !important;
  box-shadow: 0px 2px 16px #00000033 !important;
  transition: ease 0.4s;
  background-color: rgb(248, 248, 243);
}
.resourceCard:hover {
  box-shadow: 0px 2px 16px #00000080 !important;
  border: 2px solid var(--brand-color-secondary) !important;
}
.resourceCard:hover .iconbutton {
  color: white;
}
.newsCardDividerPositioning {
  border-radius: 4px;
  padding: 3px;
  width: calc(100% - 12px);
}
</style>
